<template>
  <div class="account">
    <div class="account_center">
      <!-- <h1>账号管理</h1> -->
      <header>
        <p>账号管理</p>
      </header>
      <nav>
        <!-- label-width="80px" -->
        <el-form ref="resourceRef" :model="resourceForm" :inline="true">
          <el-form-item label="登录账号" prop="loginName">
            <el-input v-model="resourceForm.loginName" @change="queryUser" size="mini" clearable></el-input>
          </el-form-item>
          <el-form-item label="账号名称" prop="userName">
            <el-input v-model="resourceForm.userName" @change="queryUser" size="mini" clearable></el-input>
          </el-form-item>
          <el-form-item label="账号状态" prop="status">
            <el-select v-model="resourceForm.status" size="mini" clearable>
              <el-option v-for="item in enableedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账号角色" prop="roleId">
            <el-select v-model="resourceForm.roleId" size="mini" clearable>
              <el-option v-for="item in roleiddata" :key="item.id" :label="item.roleName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-form-item prop="startTime" style="width:150px">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.startTime"
                size="mini"
                value-format="yyyy-MM-dd"
                clearable
              ></el-date-picker>
            </el-form-item>
            <el-form-item prop="endTime" style="width:150px">
              <el-date-picker type="date" placeholder="选择日期" v-model="resourceForm.endTime" size="mini" value-format="yyyy-MM-dd" clearable></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="queryUser" size="mini">查询</el-button>
              <el-button @click="resetfrom('resourceRef')" size="mini">重置</el-button>
            </el-form-item>
          </el-form-item>
        </el-form>
      </nav>
      <aside>
        <el-button type="primary" @click="enablebtn(null)" size="mini">批量启用</el-button>
        <el-button type="warning" @click="disabledbtn(null)" size="mini">批量禁用</el-button>
      </aside>
      <main>
        <!-- border边框 -->
        <el-table :data="resTabDatas" style="width: 100%" @selection-change="handleSelectionChange" height="458" stripe>
          <el-table-column type="selection" width="50" align="center" fixed="left"></el-table-column>
          <el-table-column type="index" width="50" align="center" label="序号" :index="computeTableIndex" fixed="left"></el-table-column>
          <el-table-column prop="loginName" label="登录账号" align="center" width="200" show-overflow-tooltip fixed="left"></el-table-column>
          <el-table-column prop="userName" label="账号名称" align="center" width="200" show-overflow-tooltip fixed="left"></el-table-column>
          <el-table-column prop="schooleName" label="所属学校" align="center" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="phonenumber" label="联系电话" align="center" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="email" label="电子邮箱" align="center" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="idCard" label="身份证号" align="center" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="address" label="联系地址" align="center" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column label="账号头像" width="100" align="center">
            <!-- slot-scope="scope" -->
            <template>
              <div class="block">
                <!-- <el-avatar :size="50" :src="scope.row.avatar.url"></el-avatar> -->
                <i></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="账号状态" width="200" align="center">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status" active-text="启用" inactive-text="禁用" @change="cutswitch(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="roleName" label="账号角色" align="center" width="100" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createTime" label="角色创建时间" align="center" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" width="150" align="center" fixed="right">
            <template slot-scope="scope">
              <!-- <el-tooltip effect="dark" content="编辑账号" placement="top"> -->
              <el-button size="mini" type="text" @click="editdata(scope.row)">
                <!-- <i class="btn_edit"></i> -->
                编辑账号
              </el-button>
              <!-- </el-tooltip> -->
              <!-- <el-tooltip effect="dark" content="绑定角色" placement="top"> -->
              <el-button size="mini" type="text" @click="record(scope.row)" style="color: #EB5757;">
                <!-- <i class="btn_view"></i> -->
                绑定角色
              </el-button>
              <!-- </el-tooltip> -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="resTabDatas"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="curpage"
          :page-sizes="[ 10, 20, 30, 40 ,50]"
          :page-size="pageSize"
          layout="total,sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </main>
      <!-- 编辑账号-->
      <el-dialog
        title="编辑账号"
        :visible.sync="edituserdig"
        width="35%"
        class="dialogs"
        :closeOnClickModal="false"
        :showClose="false"
        :closeOnPressEscape="false"
      >
        <el-form :model="edituserform" ref="editruleRef">
          <el-form-item label="登录账号" label-width="80px" prop="loginName">
            <el-input v-model="edituserform.loginName" size="mini" disabled></el-input>
          </el-form-item>
          <el-form-item label="账号名称" label-width="80px" prop="userCode">
            <el-input v-model="edituserform.userCode" size="mini" disabled></el-input>
          </el-form-item>
          <el-form-item label="联系电话" label-width="80px" prop="phoneNumber">
            <el-input v-model="edituserform.phoneNumber" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱" label-width="80px" prop="email">
            <el-input v-model="edituserform.email" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="所属学校" label-width="80px" prop="schooleName">
            <el-input v-model="edituserform.schooleName" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" label-width="80px" prop="idCard">
            <el-input v-model="edituserform.idCard" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="联系地址" label-width="80px" prop="address">
            <el-input v-model="edituserform.address" size="mini"></el-input>
          </el-form-item>
          <!-- <classcover @pirctureUrl="editpircture" :previewImg="previewImg"></classcover> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="updateUser" size="mini">确 定</el-button>
          <el-button @click="cencleeditRole" size="mini">取 消</el-button>
        </div>
      </el-dialog>
      <!-- 绑定角色 -->
      <el-dialog
        title="绑定角色"
        :visible.sync="binddig"
        width="35%"
        class="dialogs dialogtwo"
        :closeOnClickModal="false"
        :showClose="false"
        :closeOnPressEscape="false"
      >
        <p>
          <span>登录账号：{{bindname}}</span>
          <span class="spanstyle">账号名称：{{bindcord}}</span>
        </p>
        <div class="listdiv">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" size="mini" class="allcheck">全选</el-checkbox>
          <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange" class="listcheck">
            <el-checkbox v-for="item in cities" :label="item.id" :key="item.id">
              <p>{{item.roleName}}</p>
            </el-checkbox>
          </el-checkbox-group>

          <!-- <el-checkbox-group v-model="checkedCities" class="listcheck" @change="handleCheckedCitiesChange">
            <el-checkbox :label="item.roleName" v-for="item in cities" :key="item.id"></el-checkbox>
          </el-checkbox-group>-->
          <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="未选角色" name="first"></el-tab-pane>
            <el-tab-pane label="已选角色" name="second">配置管理</el-tab-pane>
          </el-tabs>-->
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="bindsure" size="mini">绑定</el-button>
          <el-button @click="cenclebind" size="mini">关闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import classcover from "./component/classcover.vue";
export default {
  components: {
    classcover,
  },
  data() {
    return {
      //多选
      multipleSelection: null,
      // 搜索表单
      resourceForm: {
        loginName: null,
        userName: null,
        roleId: null,
        status: null,
        startTime: null,
        endTime: null,
      },
      //状态
      enableedata: [
        {
          id: 0,
          name: "启用",
        },
        {
          id: 1,
          name: "禁用",
        },
      ],
      //账号角色
      roleiddata: null,
      // 角色表格
      resTabDatas: null,
      //
      // 分页
      curpage: 1, //当前页数
      pageSize: 10, //当前条数
      total: null, //总数
      //编辑账号
      edituserdig: false,
      edituserid: null,
      edituserform: {
        userCode: null,
        address: null,
        avatar: null,
        email: null,
        idCard: null,
        loginName: null,
        phoneNumber: null,
        schooleName: null,
      },
      //绑定角色
      binddig: false, //
      bindname: null, //
      bindcord: null, //
      binduserid: null, //用户id
      activeName: "first", //tabs
      //全选
      checkAll: false, //全选
      checkedCities: [], //成员选择数据
      cities: null, //成员数据
      isIndeterminate: false, //半选
    };
  },
  created() {
    this.squeryuserRole();
    // this.queryuserRole();
    this.queryUser();
  },
  methods: {
    // 查询角色
    async squeryuserRole() {
      await this.$Http.queryuserRole().then(({ data, status }) => {
        if (status == 200) {
          console.log(data, status, "查询1角色");
          this.roleiddata = data;
        }
      });
    },
    // 查询账号
    async queryUser() {
      console.log(this.resourceForm, "表单");
      const { data, status, message } = await this.$Http.queryUser({
        ...this.resourceForm,
        // endTime: endTime, //结束时间
        // loginName: loginName, //登陆账号
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
        // roleId: roleId, //角色id
        // startTime: startTime, //开始时间
        // status: status, //状态 0-正常 1-停用
        // userName: userName, //用户名称
      });
      if (status == 200) {
        console.log(data, status, "查询账号");
        if (data) {
          let aData = data.list;
          for (let a = 0; a < aData.length; a++) {
            if (aData[a].status == 0) {
              aData[a].status = true;
            } else {
              aData[a].status = false;
            }
          }
          this.resTabDatas = aData;
          // this.resTabDatas = data.list;
          this.total = data.total;
        } else {
          this.resTabDatas = data;
          this.total = 0;
        }
      }
    },
    // 重置表单
    resetfrom(formName) {
      console.log(this.$refs, "重置");
      this.$refs[formName].resetFields();
      this.queryUser();
    },
    // 开关
    cutswitch(row) {
      console.log(row, "切换开关");
      if (row.status) {
        this.enablebtn(row.userId);
        // this.disabledbtn(row.userId);
      } else {
        this.disabledbtn(row.userId);
        // this.enablebtn(row.userId);
      }
    },
    // 启用/批量启用
    async enablebtn(id) {
      console.log(id, "开始1id");
      //   return;
      let ids = [];
      if (id == null) {
        for (let a = 0; a < this.multipleSelection.length; a++) {
          ids.push(this.multipleSelection[a].userId);
        }
      } else {
        ids.push(id);
      }
      const { data, status } = await this.$Http.updateUserStatusEnable({
        userList: ids, //角色id数组
      });
      if (status == 200) {
        console.log(data, status, "启用角色");
        this.queryUser();
      }
    },
    // 禁用/批量禁用
    async disabledbtn(id) {
      console.log(id, "结束1id");
      let ids = [];
      if (id == null) {
        for (let a = 0; a < this.multipleSelection.length; a++) {
          ids.push(this.multipleSelection[a].userId);
        }
      } else {
        ids.push(id);
      }
      const { data, status } = await this.$Http.updateUserStatusDisable({
        userList: ids, //角色id数组
      });
      if (status == 200) {
        console.log(data, status, "禁用角色");
        this.queryUser();
      }
    },

    // ----D
    //编辑角色
    editdata(row) {
      console.log(row, "编辑角色");
      this.edituserid = row.userId;
      this.queryUserDetailById(row.userId);
      //   this.edituserform.roleName = row.roleName;
      //   this.edituserform.remark = row.remark;
      //   this.edituserform.id = row.id;
      this.edituserdig = true;
    },
    // 查询角色详情
    async queryUserDetailById(id) {
      let form = new FormData();
      form.append("userId", id);
      await this.$Http.queryUserDetailById(form).then((res) => {
        if (res.status == 200) {
          console.log(res, "查询详情");
          let aData = res.data;
          this.edituserform.userCode = aData.userName; //用户名称
          this.edituserform.loginName = aData.loginName; //登陆账号
          this.edituserform.phoneNumber = aData.phonenumber;
          this.edituserform.email = aData.email;
          this.edituserform.schooleName = aData.schooleName;
          this.edituserform.idCard = aData.idCard;
          this.edituserform.address = aData.address;
        }
      });
    },
    // --确定
    async updateUser() {
      const { data, status } = await this.$Http.updateUser({
        ...this.edituserform,
        // address: address, //地址
        // avatar: avatar, //头像id
        // email: email, //邮箱
        // idCard: idCard, //身份证号
        // loginName: loginName, //登陆账号
        // phoneNumber: phoneNumber, //手机号
        // schooleName: schooleName, //学校名称
        // userCode: userCode, // 用户名称
        userId: this.edituserid, //用户id
      });
      if (status == 200) {
        console.log(data, status, "修改角色");
        this.cencleeditRole();
        this.queryUser();
      }
    },
    // --取消
    cencleeditRole() {
      this.$refs["editruleRef"].resetFields();
      this.edituserdig = false;
    },
    // ---
    // 绑定角色
    record(row) {
      console.log(row, "绑定角色");
      this.queryuserRole();
      this.binduserid = row.userId;
      this.bindname = row.loginName;
      this.bindcord = row.userName;
      this.checkedCities = row.roleIdList || [];
      this.handleCheckedCitiesChange(row.roleIdList);

      this.binddig = true;
    },
    // 查询角色
    async queryuserRole() {
      await this.$Http.queryuserRole().then((res) => {
        console.log(res, "查询角色");
        this.cities = res.data;
      });
    },
    // 全选
    handleCheckAllChange(val) {
      console.log(val, "全选按钮11");
      console.log(this.cities, "aaa");
      let citys = [];
      for (let x = 0; x < this.cities.length; x++) {
        citys.push(this.cities[x].id);
      }
      console.log(citys, "bbb");
      this.checkedCities = val ? citys : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      console.log(value, "全选数据");
      if (value == undefined) {
        this.isIndeterminate = false;
        this.checkAll = false;
        return;
      }
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    //绑定
    async bindsure() {
      console.log(this.checkedCities, "绑定");
      // if (this.checkedCities.length == 0) {
      //   // this.$message.warning("错了哦，这是一条错误消息");
      //   this.$message.warning("请选择要赋予的角色");
      // }
      // else {
      const { data, status } = await this.$Http.saveUserRole({
        roleId: this.checkedCities, //角色ID
        userId: this.binduserid, //用户id
      });
      if (status == 200) {
        console.log(data, status, "绑定");
        this.$message.success("绑定成功");
        this.queryUser();
        this.cenclebind();
      }
      // }
    },
    cenclebind() {
      this.binddig = false;
      // this.checkedCities = [];
    },
    // 多选
    handleSelectionChange(val) {
      console.log(val, "xuzhogn");
      this.multipleSelection = val;
    },
    //监听index
    computeTableIndex(index) {
      // console.log(index, this.queryInfo.pagenum, this.queryInfo.pagesize, 'e')
      return (this.curpage - 1) * this.pageSize + index + 1;
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.queryUser();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.curpage = val;
      this.queryUser();
    },
  },
};
</script>
<style lang="scss" scoped>
.account {
  .account_center {
    header {
      // width: 1688px;
      height: 70px;
      background: #ffffff;
      padding: 0 20px;
      p {
        // width: 108px;
        height: 70px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 600;
        color: #141516;
        line-height: 70px;
      }
    }
    nav {
      background-color: #fff;
      margin: 10px 0;
      padding: 20px;
      .el-input {
        width: 150px;
      }
      .el-select {
        width: 120px;
      }
    }
    aside {
      margin-top: 10px;
      background-color: #fff;
      padding: 20px;
    }
    main {
      padding: 0 20px;
      background-color: #fff;
      .block {
        i {
          display: inline-block;
          width: 48px;
          height: 48px;
          background: url("../../../../assets/components/people.png") no-repeat;
        }
      }
      .el-pagination {
        display: flex;
        justify-content: center;
        padding: 20px 0;
      }
    }

    .dialogs {
      ::v-deep .el-dialog__title {
        // width: 72px;
        // height: 24px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 600;
        color: #141516;
        // line-height: 21px;
      }
      ::v-deep .el-dialog__body {
        padding: 10px 20px 0;
      }
      .dialogp {
        // width: 140px;
        // height: 18px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #5d6066;
        // line-height: 16px;
      }

      .el-input {
        width: 400px;
      }
      .el-textarea {
        width: 400px;
      }
      .dialog-footer {
        display: flex;
        justify-content: center;
      }
    }
    .dialogtwo {
      .allcheck {
        padding: 0 10px;
        margin: 0;
        height: 25px;
        line-height: 25px;
        // border-bottom: 1px solid #ebebeb;
      }
      p {
        // padding: 10px 0;
        span {
          display: inline-block;
          //   width: 134px;
          //   height: 18px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5d6066;
          //   line-height: 16px;
        }
        .spanstyle {
          display: inline-block;
          margin-left: 20px;
        }
      }
      .listdiv {
        margin-top: 10px;
        max-height: 500px;
        .listcheck {
          display: flex;
          //   justify-content: center;
          flex-direction: column;
          .el-checkbox {
            padding: 0 10px;
            margin: 0;
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid #ebebeb;
          }
        }
      }
    }
  }
}
</style>