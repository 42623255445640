<template>
  <div class="allcir">
    <div class="allcircenter">
      <!-- <h1>全部教研圈</h1> -->
      <nav>
        <!-- label-width="80px"  -->
        <el-form ref="resourceRef" :model="resourceForm" :inline="true">
          <el-form-item label="教研圈名称" prop="circleName">
            <el-input v-model="resourceForm.circleName" @change="queryCourse" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="创建者" prop="createUser">
            <el-input v-model="resourceForm.createUser" @change="queryCourse" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="学科类型" prop="subjectTypeId">
            <el-select v-model="resourceForm.subjectTypeId" @change="changeshuject" size="mini">
              <el-option v-for="item in subjectypedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科" prop="subjectId">
            <el-select v-model="resourceForm.subjectId" size="mini">
              <el-option v-for="item in subjectdata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学段" prop="periodId">
            <el-select v-model="resourceForm.periodId" @change="changeperiod" size="mini">
              <el-option v-for="item in perioddata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级" prop="gradeId">
            <el-select v-model="resourceForm.gradeId" size="mini">
              <el-option v-for="item in gradedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="教研圈创建时间">
            <el-form-item prop="startTime" style="width:130px">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.startTime"
                style="width: 100%;"
                size="mini"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item prop="endTime" style="width:130px">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.endTime"
                style="width: 100%;"
                size="mini"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-form-item>
        </el-form>
        <div class="nav_btns">
          <el-button type="primary" @click="queryCourse" size="mini">查询</el-button>
          <el-button @click="resetfrom('resourceRef')" size="mini">重置</el-button>
        </div>
      </nav>
      <div class="blank"></div>
      <aside>
        <el-button type="primary" @click="recommondbtn(null)" size="mini">批量推荐</el-button>
      </aside>
      <main>
        <!-- border边框 -->
        <el-table :data="resTabDatas" style="width: 100%" @selection-change="handleSelectionChange" height="472" stripe>
          <el-table-column type="selection" width="50" align="center" fixed="left"></el-table-column>
          <el-table-column type="index" width="50" align="center" label="序号" :index="computeTableIndex" fixed="left"></el-table-column>
          <el-table-column prop="circleName" label="教研圈名称" align="center" width="200" show-overflow-tooltip fixed="left"></el-table-column>
          <el-table-column prop="periodName" label="学段" align="center" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="gradeName" label="年级" align="center" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="subjectTypeName" label="学科类型" align="center" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="subjectName" label="学科" align="center" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createUser" label="创建者" align="center" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="memberNo" label="成员人数" align="center" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createTime" label="教研圈创建时间" align="center" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" width="200" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="view(scope.row)">查看教研圈</el-button>
              <el-button size="mini" type="text" @click="recommondbtn(scope.row.id)" style="color: #EB5757;">推荐教研圈</el-button>
              <!-- <el-button size="mini" type="text" @click="recommondbtn(scope.row.id)" style="color: #EB5757;">查看审核记录</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="resTabDatas"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="curpage"
          :page-sizes="[ 10, 20, 30, 40 ,50]"
          :page-size="pageSize"
          layout="total,sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 表单
      resourceForm: {
        circleName: null,
        createUser: null,
        subjectTypeId: null,
        subjectId: null,
        periodId: null,
        gradeId: null,
        endTime: null,
      },
      //学科类型
      subjectypedata: null,
      // 学科
      subjectdata: null,
      // 学段
      perioddata: null,
      // 年级
      gradedata: null,
      //  表格数据
      resTabDatas: null,

      //
      // 分页
      curpage: 1, //当前页数
      pageSize: 10, //当前条数
      total: null, //总数
    };
  },
  created() {
    this.queryCourse();
    this.querySubjectType(); //获取课程类型
    this.querySubject(0); //获取学科信息
    this.queryPeriod(); //获取学段
    this.queryGrade(0); //获取年级信息
  },
  methods: {
    // 查询教研圈
    async queryCourse() {
      const { data, status } = await this.$Http.queryNoRecommendCircle({
        ...this.resourceForm,
        // circleName :circleName ,//课程名称
        // createUser :createUser ,
        // endTime:endTime,//结束时间
        // gradeId:gradeId,//年级id
        pageNo: this.curpage, //页数
        pageSize: this.pageSize, //每页数量
        // periodId:periodId,//学段id
        // startTime:startTime,//开始时间
        // subjectId:subjectId,//学科id
        // subjectTypeId:subjectTypeId,//学科类型id
      });
      if (status == 200) {
        console.log(data, status, "查询教研圈");
        if (data == null) {
          this.resTabDatas = data;
          this.total = 0;
        } else {
          let aData = data.list;
          for (let x = 0; x < aData.length; x++) {
            aData[x].periodName = aData[x].periodName.join("/");
            aData[x].gradeName = aData[x].gradeName.join("/");
            aData[x].subjectName = aData[x].subjectName.join("/");
            // aData[x].subjectType = aData[x].subjectType.join("/");
          }
          this.resTabDatas = aData;
          this.total = data.total;
        }
      } else {
        this.resTabDatas = null;
        this.total = 0;
      }
    },
    // 获取学科类型
    async querySubjectType() {
      await this.$Http.querySubjectType().then((res) => {
        console.log(res, "获取课程类型");
        if (res.status == 200) {
          this.subjectypedata = res.data;
        }
      });
    },
    // 获取学科
    async querySubject(id) {
      console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.querySubject({
        idList: ids,
      });
      if (status == 200) {
        console.log(data, status, "获取学科");
        this.subjectdata = data;
      }
    },
    // 获取学段
    async queryPeriod() {
      await this.$Http.queryPeriod().then((res) => {
        console.log(res, "获取学段");
        if (res.status == 200) {
          this.perioddata = res.data;
        }
      });
    },
    // 获取年级信息
    async queryGrade(id) {
      console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.queryGrade({
        idList: ids,
      });
      if (status == 200) {
        console.log(data, status, "获取年级信息");
        this.gradedata = data;
      }
    },
    // 学科类型的值得变化
    changeshuject(val) {
      this.querySubject(val);
    },
    // 学段的值得变化
    changeperiod(val) {
      this.queryGrade(val);
    },
    //
    // 重置表单
    resetfrom(formName) {
      console.log(this.$refs, "重置");
      this.$refs[formName].resetFields();
      this.curpage = 1;
      this.pageSize = 10;
      this.queryCourse();
    },
    //推荐课程
    async recommondbtn(id) {
      console.log(id, "推荐");
      return;
      let ids = [];
      if (id == null) {
        if (
          this.multipleSelection == null ||
          this.multipleSelection.length == 0
        ) {
          this.$message.warning("请选择要推荐的课程");
          return;
        } else {
          for (let a = 0; a < this.multipleSelection.length; a++) {
            ids.push(this.multipleSelection[a].id);
          }
        }
      } else {
        ids.push(id);
      }
      this.$confirm("是否确定推荐", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data, status } = await this.$Http.recommendCourse({
            courseIds: ids, //角色id数组
          });
          if (status == 200) {
            console.log(data, status, "推荐课程");
            this.$message({
              type: "success",
              message: "推荐成功",
            });
            this.curpage = 1;
            this.queryGrade();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 查看课程
    view(row) {
      console.log(row, "查看课程");
    },
    //
    // 多选
    handleSelectionChange(val) {
      console.log(val, "xuzhogn");
      this.multipleSelection = val;
    },
    //监听index
    computeTableIndex(index) {
      // console.log(index, this.queryInfo.pagenum, this.queryInfo.pagesize, 'e')
      return (this.curpage - 1) * this.pageSize + index + 1;
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.queryCourse();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.curpage = val;
      this.queryCourse();
    },
  },
};
</script>
<style lang="scss" scoped>
.allcir {
  .allcircenter {
    nav {
      background-color: #fff;
      padding: 0 20px 20px;
      // .el-form-item{
      //   width: 230px;
      // }
      .el-input {
        width: 120px;
      }
      .el-select {
        width: 120px;
      }
      .nav_btns {
        display: flex;
        justify-content: flex-end;
      }
    }
    .blank {
      width: 100%;
      height: 12px;
      background: #f3f4f7;
    }
    aside {
      margin-top: 10px;
      background-color: #fff;
      padding: 20px;
    }
    main {
      padding: 0 20px;
      background-color: #fff;
      .el-pagination {
        display: flex;
        justify-content: center;
        padding: 20px 0;
      }
    }
  }
}
</style>