<template>
  <div class="circlerecom">
    <div class="circlerecomcenter">
      <!-- <h1>课程推荐管理</h1> -->
      <header>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="全部教研圈" name="first">
            <allcir></allcir>
          </el-tab-pane>
          <el-tab-pane label="推荐教研圈" name="second">
            <postcir></postcir>
          </el-tab-pane>
        </el-tabs>
      </header>
    </div>
  </div>
</template>
<script>
import allcir from "./components/allcir.vue";
import postcir from "./components/postcir.vue";
export default {
  components: {
    allcir,
    postcir,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    // 切换tabs
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>
<style lang="scss" scoped>
.circlerecom {
  .circlerecomcenter {
    header {
      background: #ffffff;
      //   border-radius: 4px 4px 4px 4px;
      .el-tabs {
        ::v-deep .el-tabs__item {
          //   background-color: pink;
          //   width: 72px;
          //   height: 24px;
          //   font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 600;
          color: #141516;
          //   line-height: 21px;
        }
        ::v-deep .el-tabs__header {
          padding: 10px 20px 0;
        }
      }
    }
  }
}
</style>