<template>
  <div class="label">
    <div class="labelenter">
      <!-- <h1>标签管理</h1> -->
      <header>
        <p>系列课程标签管理</p>
      </header>
      <nav>
        <!-- label-width="80px" -->
        <el-form ref="resourceRef" :model="resourceForm" :inline="true">
          <el-form-item label="标签名称" prop="labelName">
            <el-input v-model="resourceForm.labelName" @change="queryGrade" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="标签状态" prop="enable">
            <el-select v-model="resourceForm.enable" size="mini">
              <el-option v-for="item in enableedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-form-item prop="startTime" style="width:150px">
              <el-date-picker type="date" placeholder="选择日期" v-model="resourceForm.startTime" size="mini" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item prop="endTime" style="width:150px">
              <el-date-picker type="date" placeholder="选择日期" v-model="resourceForm.endTime" size="mini" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="queryGrade" size="mini">查询</el-button>
              <el-button @click="resetfrom('resourceRef')" size="mini">重置</el-button>
            </el-form-item>
          </el-form-item>
        </el-form>
      </nav>
      <aside>
        <div>
          <el-button type="primary" @click="enablebtn(null)" size="mini">批量启用</el-button>
          <el-button type="warning" @click="disabledbtn(null)" size="mini">批量禁用</el-button>
          <el-button type="danger" @click="edlbtn(null)" size="mini">批量删除标签</el-button>
        </div>
        <div>
          <el-button type="primary" @click="addperiod(null)" size="mini">新建标签</el-button>
        </div>
      </aside>
      <main>
        <!-- border边框 -->
        <el-table :data="resTabDatas" style="width: 100%" @selection-change="handleSelectionChange" height="472" stripe>
          <el-table-column type="selection" width="50" align="center" fixed="left"></el-table-column>
          <el-table-column type="index" width="50" align="center" label="序号" :index="computeTableIndex" fixed="left"></el-table-column>
          <el-table-column prop="labelName" label="系列课程标签名称" align="center" width="450" show-overflow-tooltip fixed="left"></el-table-column>
          <!-- <el-table-column prop="periodName" label="所属标签" align="center" width="300" show-overflow-tooltip fixed="left"></el-table-column> -->
          <el-table-column label="标签状态" width="300" align="center">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.enable" active-text="启用" inactive-text="禁用" @change="cutswitch(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="createDate" label="上传时间" align="center" width="450" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" width="200" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="editdata(scope.row)">编辑标签</el-button>
              <el-button size="mini" type="text" @click="edlbtn(scope.row.id)" style="color: #EB5757;">删除标签</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="resTabDatas"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="curpage"
          :page-sizes="[ 10, 20, 30, 40 ,50]"
          :page-size="pageSize"
          layout="total,sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </main>

      <!-- 新增标签-->
      <el-dialog
        title="新增标签"
        :visible.sync="addperioddig"
        width="20%"
        class="dialogs"
        :closeOnClickModal="false"
        :showClose="false"
        :closeOnPressEscape="false"
      >
        <el-form :model="addperiodform" ref="addruleRef">
          <el-form-item label="标签名称" label-width="80px" prop="labelName">
            <el-input v-model="addperiodform.labelName" size="mini" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sureadd" size="mini">新建</el-button>
          <el-button @click="shutdig" size="mini">关闭</el-button>
        </div>
      </el-dialog>
      <!-- 编辑标签-->
      <el-dialog
        title="编辑标签"
        :visible.sync="editperioddig"
        width="20%"
        class="dialogs"
        :closeOnClickModal="false"
        :showClose="false"
        :closeOnPressEscape="false"
      >
        <el-form :model="editperiodform" ref="editruleRef">
          <el-form-item label="标签名称" label-width="80px" prop="labelName">
            <el-input v-model="editperiodform.labelName" size="mini" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sureedit" size="mini">修改</el-button>
          <el-button @click="shuteditdig" size="mini">关闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //多选
      multipleSelection: null,
      // 表单
      resourceForm: {
        labelName: null,
        enable: null,
        startTime: null,
        endTime: null,
      },
      // 状态
      enableedata: [
        {
          id: 0,
          name: "禁用",
        },
        {
          id: 1,
          name: "启用",
        },
      ],
      // 表格数据
      resTabDatas: null,
      //
      // 分页
      curpage: 1, //当前页数
      pageSize: 10, //当前条数
      total: null, //总数

      // // 新增标签
      addperioddig: false,
      addperiodform: {
        labelName: null,
      },

      // //编辑标签
      editperioddig: false,
      editperiodform: {
        labelName: null,
        id: null,
      },
    };
  },
  created() {
    this.queryGrade();
  },
  methods: {
    // 查询标签管理
    async queryGrade() {
      console.log(this.resourceForm, "resourceForm");
      // return;
      const { data, status } = await this.$Http.querySeriseLabel({
        ...this.resourceForm,
        // enable: enable, //状态
        // endTime: endTime, //结束时间
        pageNo: this.curpage, //页数
        pageSize: this.pageSize, //每页数量
        // labelName: labelName, //	标签名称
        // startTime: startTime, //开始时间
      });
      if (status == 200) {
        console.log(data, status, "查询标签管理");
        if (data == null) {
          this.resTabDatas = null;
          this.total = 0;
        } else {
          let aData = data.list;
          for (let a = 0; a < aData.length; a++) {
            if (aData[a].enable == 1) {
              aData[a].enable = true;
            } else {
              aData[a].enable = false;
            }
          }
          this.resTabDatas = aData;
          this.total = data.total;
        }
      }
    },
    // 重置表单
    resetfrom(formName) {
      console.log(this.$refs, "重置");
      this.$refs[formName].resetFields();
      this.queryGrade();
    },
    // 开关
    cutswitch(row) {
      console.log(row, "切换开关");
      if (row.enable) {
        this.enablebtn(row.id);
      } else {
        this.disabledbtn(row.id);
      }
    },
    // 启用/批量启用
    async enablebtn(id) {
      console.log(id, "开始1id", this.multipleSelection);
      //   return;
      let ids = [];
      if (id == null) {
        if (
          this.multipleSelection == null ||
          this.multipleSelection.length == 0
        ) {
          this.$message.warning("请选择要启用的标签");
          return;
        } else {
          for (let a = 0; a < this.multipleSelection.length; a++) {
            ids.push(this.multipleSelection[a].id);
          }
        }
      } else {
        ids.push(id);
      }
      const { data, status } = await this.$Http.updatelabelEnable({
        idList: ids, //角色id数组
      });
      if (status == 200) {
        console.log(data, status, "启用角色");
        this.queryGrade();
      }
    },
    // 禁用/批量禁用
    async disabledbtn(id) {
      console.log(id, "结束1id");
      let ids = [];
      if (id == null) {
        if (
          this.multipleSelection == null ||
          this.multipleSelection.length == 0
        ) {
          this.$message.warning("请选择要禁用的标签");
          return;
        } else {
          for (let a = 0; a < this.multipleSelection.length; a++) {
            ids.push(this.multipleSelection[a].id);
          }
        }
      } else {
        ids.push(id);
      }
      const { data, status } = await this.$Http.updatelabelDisable({
        idList: ids, //角色id数组
      });
      if (status == 200) {
        console.log(data, status, "禁用角色");
        this.queryGrade();
      }
    },
    // 批量删除/删除
    async edlbtn(id) {
      console.log(id, "删除");
      let ids = [];
      if (id == null) {
        if (
          this.multipleSelection == null ||
          this.multipleSelection.length == 0
        ) {
          this.$message.warning("请选择要删除的标签");
          return;
        } else {
          for (let a = 0; a < this.multipleSelection.length; a++) {
            ids.push(this.multipleSelection[a].id);
          }
        }
      } else {
        ids.push(id);
      }
      this.$confirm("是否确定删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data, status } = await this.$Http.deleteLabel({
            idList: ids, //角色id数组
          });
          if (status == 200) {
            console.log(data, status, "删除标签");
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.curpage = 1;
            this.queryGrade();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //---新增标签------
    addperiod() {
      console.log("新建标签");
      this.addperioddig = true;
    },
    // 新建
    async sureadd() {
      const res = await this.$Http.saveSeriseLabel({
        ...this.addperiodform,
      });
      if (res.status == 200) {
        // console.log(res.data, res.status, "新建");
        this.$message.success("新建成功");
        this.shutdig();
      }
    },
    //关闭
    shutdig() {
      this.cleanadd();
      this.addperioddig = false;
      this.queryGrade();
    },
    // 清空
    cleanadd() {
      this.$refs["addruleRef"].resetFields();
    },

    //----编辑标签-------
    editdata(row) {
      console.log(row, "编辑标签");
      this.queryGradeById(row.id);
      this.editperioddig = true;
    },
    // 查询标签
    async queryGradeById(id) {
      let form = new FormData();
      form.append("id", id);
      const { data, status } = await this.$Http.querySeriseLabelById(form);
      if (status == 200) {
        console.log(data, status, "查询");
        this.editperiodform.labelName = data.labelName;
        this.editperiodform.id = data.id;
      }
    },

    //修改标签
    async sureedit() {
      const res = await this.$Http.updateSeriseLabel({
        ...this.editperiodform,
      });
      if (res.status == 200) {
        console.log(res, "修改标签");
        this.$message.success("修改成功");
        this.shuteditdig();
      }
    },
    //关闭
    shuteditdig() {
      this.cleanadds();
      this.editperioddig = false;
      this.queryGrade();
    },
    // 清空
    cleanadds() {
      this.$refs["editruleRef"].resetFields();
    },

    //
    // 多选
    handleSelectionChange(val) {
      console.log(val, "xuzhogn");
      this.multipleSelection = val;
    },
    //监听index
    computeTableIndex(index) {
      // console.log(index, this.queryInfo.pagenum, this.queryInfo.pagesize, 'e')
      return (this.curpage - 1) * this.pageSize + index + 1;
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.queryGrade();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.curpage = val;
      this.queryGrade();
    },
  },
};
</script>
<style lang="scss" scoped>
.label {
  .labelenter {
    header {
      // width: 1688px;
      height: 70px;
      background: #ffffff;
      padding: 0 20px;
      p {
        // width: 108px;
        height: 70px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 600;
        color: #141516;
        line-height: 70px;
      }
    }
    nav {
      background-color: #fff;
      margin: 10px 0;
      padding: 20px;
      .el-input {
        width: 150px;
      }
      .el-select {
        width: 120px;
      }
    }
    aside {
      margin-top: 10px;
      background-color: #fff;
      padding: 20px;
      display: flex;
      justify-content: space-between;
    }
    main {
      padding: 0 20px;
      background-color: #fff;
      .el-pagination {
        display: flex;
        justify-content: center;
        padding: 20px 0;
      }
    }
    .dialogs {
      ::v-deep .el-dialog__title {
        // width: 72px;
        // height: 24px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 600;
        color: #141516;
        // line-height: 21px;
      }
      ::v-deep .el-dialog__body {
        padding: 10px 20px 0;
      }
      .dialogp {
        // width: 140px;
        // height: 18px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #5d6066;
        // line-height: 16px;
      }

      .el-input {
        width: 240px;
      }
      .el-select {
        width: 240px;
      }
      .dialog-footer {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>