<template>
  <div class="aserclass">
    <div class="aserclasscenter">
      <!-- <h1>系列课程1</h1> -->
      <nav>
        <!-- label-width="80px"  -->
        <el-form ref="resourceRef" :model="resourceForm" :inline="true">
          <el-form-item label="课程名称" prop="courseName">
            <el-input v-model="resourceForm.courseName" @change="queryCourse" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="上传者" prop="userName">
            <el-input v-model="resourceForm.userName" @change="queryCourse" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="学科类型" prop="subjectTypeId">
            <el-select v-model="resourceForm.subjectTypeId" @change="changeshuject" size="mini">
              <el-option v-for="item in subjectypedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科" prop="subjectId">
            <el-select v-model="resourceForm.subjectId" size="mini">
              <el-option v-for="item in subjectdata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学段" prop="periodId">
            <el-select v-model="resourceForm.periodId" @change="changeperiod" size="mini">
              <el-option v-for="item in perioddata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级" prop="gradeId">
            <el-select v-model="resourceForm.gradeId" size="mini">
              <el-option v-for="item in gradedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程创建时间">
            <el-form-item prop="startTime" style="width:150px">
              <el-date-picker type="date" placeholder="选择日期" v-model="resourceForm.startTime" style="width: 100%;" size="mini" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item prop="endTime" style="width:150px">
              <el-date-picker type="date" placeholder="选择日期" v-model="resourceForm.endTime" style="width: 100%;" size="mini" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-form-item>
        </el-form>
        <div class="nav_btns">
          <el-button type="primary" @click="queryCourse" size="mini">查询</el-button>
          <el-button @click="resetfrom('resourceRef')" size="mini">重置</el-button>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 表单
      resourceForm: {
        courseName: null,
        userName: null,
        subjectTypeId: null,
        subjectId: null,
        periodId: null,
        gradeId: null,
        endTime: null,
      },
      //学科类型
      subjectypedata: null,
      // 学科
      subjectdata: null,
      // 学段
      perioddata: null,
      // 年级
      gradedata: null,
    };
  },
  created() {
    this.querySubjectType(); //获取课程类型
    this.querySubject(0); //获取学科信息
    this.queryPeriod(); //获取学段
    this.queryGrade(0); //获取年级信息
  },
  methods: {
    // 课程管理/全部课程--普通课程
    async queryCourse() {
      const { data, status } = await this.$Http.queryCourse({
        // courseName:courseName,//课程名称
        // endTime:endTime,//结束时间
        // gradeId:gradeId,//年级id
        pageNo: pageNo, //页数
        pageSize: pageSize, //每页数量
        // periodId:periodId,//学段id
        // startTime:startTime,//开始时间
        // subjectId:subjectId,//学科id
        // subjectTypeId:subjectTypeId,//学科类型id
        // userName:userName,//用户名
      });
      if (status == 200) {
        console.log(data, status, "课程管理/全部课程--普通课程");
      }
    },
    // 获取学科类型
    async querySubjectType() {
      await this.$Http.querySubjectType().then((res) => {
        console.log(res, "获取课程类型");
        if (res.status == 200) {
          this.subjectypedata = res.data;
        }
      });
    },
    // 获取学科
    async querySubject(id) {
      console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.querySubject({
        idList: ids,
      });
      if (status == 200) {
        console.log(data, status, "获取学科");
        this.subjectdata = data;
      }
    },
    // 获取学段
    async queryPeriod() {
      await this.$Http.queryPeriod().then((res) => {
        console.log(res, "获取学段");
        if (res.status == 200) {
          this.perioddata = res.data;
        }
      });
    },
    // 获取年级信息
    async queryGrade(id) {
      console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.queryGrade({
        idList: ids,
      });
      if (status == 200) {
        console.log(data, status, "获取年级信息");
        this.gradedata = data;
      }
    },
    // 学科类型的值得变化
    changeshuject(val) {
      this.querySubject(val);
    },
    // 学段的值得变化
    changeperiod(val) {
      this.queryGrade(val);
    },
  },
};
</script>