<template>
  <div class="rightfrom">
    <div class="empty" v-if="!currentIndex" style="min-height:745px;color:#5d6066;">
      <h1>欢迎来到后台管理系统</h1>
    </div>
    <div class="right_center" v-else>
      <!-- 账号管理 -->
      <account v-if="currentIndex=='1-1'"></account>
      <!-- 角色管理 -->
      <role v-if="currentIndex=='1-2'"></role>

      <!-- 敏感词 -->
      <sensitiveWords v-if="currentIndex=='1-3'"></sensitiveWords>

      <!-- 学段管理 -->
      <grade v-if="currentIndex=='2-1'"></grade>
      <!-- 年级管理 -->
      <period v-if="currentIndex=='2-2'"></period>
      <!-- 学科管理 -->
      <kcsls v-if="currentIndex=='2-3'"></kcsls>
      <!-- 资源类型管理 -->
      <resources v-if="currentIndex=='2-4'"></resources>
      <!-- 标签管理 -->
      <labels v-if="currentIndex=='2-5'"></labels>

      <!-- 课程推荐管理 -->
      <classrecom v-if="currentIndex=='3-1'"></classrecom>
      <circlerecom v-if="currentIndex=='3-3'"></circlerecom>
    </div>
  </div>
</template>
<script>
import role from "./components/system/role.vue";
import account from "./components/system/account.vue";
import sensitiveWords from "./components/system/sensitiveWords.vue";
//
import grade from "./components/basis/grade.vue";
import period from "./components/basis/period.vue";
import kcsls from "./components/basis/class.vue";
import resources from "./components/basis/resources.vue";
import labels from "./components/basis/label.vue";
//
import classrecom from "./components/businss/classrecom.vue";
import circlerecom from "./components/businss/circlerecom.vue";
export default {
  props: {
    currentIndex: {
      type: String,
      default: null,
    },
  },
  components: {
    role,
    account,
    sensitiveWords,
    //
    grade,
    period,
    kcsls,
    resources,
    labels,
    //
    classrecom,
    circlerecom,
  },
  data() {
    return {};
  },
  Resource() {
    return {};
  },
  created() {
    console.log(this.currentIndex, "currentIndex");
  },
};
</script>
<style lang="scss" scoped>
.rightfrom {
  .right_center {
    padding: 0px 20px 10px 10px;
  }
  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>