<template>
  <div class="leftsub">
    <div class="left_center">
      <!-- default-active="1-1" :default-active="defaultindex"-->
      <el-menu class="el-menu-vertical-demo" :unique-opened="true" @select="handleSelect">
        <div class="left_menu" v-for="item in sublist" :key="item.id">
          <el-submenu :index="item.numIndex" v-if="item.children!==undefined">
            <template slot="title">
              <!-- <i class="el-icon-location"></i> -->
              <span>{{item.content}}</span>
            </template>
            <el-menu-item v-for="items in item.children" :key="items.ids" :index="items.numIndex">
              <!-- <i class="el-icon-menu"></i> -->
              <span slot="title">{{items.content}}</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item :index="item.numIndex" v-else>
            <!-- <i class="el-icon-menu"></i> -->
            <span slot="title">{{item.content}}</span>
          </el-menu-item>
        </div>
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sublist: null,
    };
  },
  created() {
    this.querylist();
  },
  methods: {
    // 获取列表权限
    querylist() {
      this.$Http.getBackageMenu().then((res) => {
        console.log(res.data, "获取列表11");
        this.sublist = res.data;
      });
      return
      let aa = [
        {
          id: 1,
          content: "系统管理",
          numIndex: "1",
          children: [
            {
              ids: 1,
              content: "角色管理",
              numIndex: "1-1",
            },
            {
              ids: 2,
              content: "账号管理",
              numIndex: "1-2",
            },
            {
              ids: 3,
              content: "敏感词管理",
              numIndex: "1-3",
            },
          ],
        },
        {
          id: 2,
          content: "基础数据管理",
          numIndex: "2",
          children: [
            {
              ids: 1,
              content: "学段管理",
              numIndex: "2-1",
            },
            {
              ids: 2,
              content: "年级管理",
              numIndex: "2-2",
            },
            {
              ids: 3,
              content: "学科管理",
              numIndex: "2-3",
            },
            {
              ids: 4,
              content: "资源类型管理",
              numIndex: "2-4",
            },
            {
              ids: 5,
              content: "标签管理",
              numIndex: "2-5",
            },
          ],
        },
        {
          id: 3,
          content: "业务管理",
          numIndex: "3",
          children: [
            {
              ids: 1,
              content: "课程推荐管理",
              numIndex: "3-1",
            },
            {
              ids: 2,
              content: "资源推荐管理",
              numIndex: "3-2",
            },
            {
              ids: 3,
              content: "教研圈推荐管理",
              numIndex: "3-3",
            },
            {
              ids: 4,
              content: "通知公告推荐管理",
              numIndex: "3-4",
            },
          ],
        },
        // {
        //   id: 4,
        //   content: "党建品牌管理",
        //   numIndex: "4",
        // },
        // {
        //   id: 5,
        //   content: "成果推广管理",
        //   numIndex: "5",
        // },
        // {
        //   id: 6,
        //   content: "新闻动态管理",
        //   numIndex: "6",
        // },
        // {
        //   id: 7,
        //   content: "特色项目管理",
        //   numIndex: "7",
        // },
        // {
        //   id: 8,
        //   content: "证书管理",
        //   numIndex: "8",
        // },
        // {
        //   id: 9,
        //   content: "数据统计",
        //   numIndex: "9",
        // },
        // {
        //   id: 10,
        //   content: "通知推送",
        //   numIndex: "10",
        // },
      ];
      this.sublist = aa;
    },
    //当前激活的菜单
    handleSelect(index, indexpath) {
      console.log(index, indexpath, "当前激活的菜单");
      this.$emit("showindex", index);
    },
  },
};
</script>
<style lang="scss" scoped>
.leftsub {
  .left_center {
  }
}
</style>