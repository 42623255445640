<template>
  <div class="resource">
    <div class="resource_center">
      <!-- <h1>角色管理</h1> -->
      <header>
        <p>角色管理</p>
        <el-button type="primary" @click="createuser" size="mini">
          <!-- <i class="btns_icon"></i> -->
          创建新角色
        </el-button>
      </header>
      <nav>
        <!-- label-width="80px" -->
        <el-form ref="resourceRef" :model="resourceForm" :inline="true">
          <el-form-item label="角色名称" prop="roleName">
            <el-input v-model="resourceForm.roleName" @change="queryRole" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="enable">
            <el-select v-model="resourceForm.enable" size="mini">
              <el-option v-for="item in enableedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="角色创建时间">
            <el-form-item prop="startTime" style="width:150px">
              <el-date-picker type="date" placeholder="选择日期" v-model="resourceForm.startTime" size="mini"
                value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item prop="endTime" style="width:150px">
              <el-date-picker type="date" placeholder="选择日期" v-model="resourceForm.endTime" size="mini"
                value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="queryRole" size="mini">查询</el-button>
              <el-button @click="resetfrom('resourceRef')" size="mini">重置</el-button>
            </el-form-item>
          </el-form-item>
        </el-form>
        <!-- <div class="nav_btns">
          <el-button type="primary" @click="queryRole" size="mini">查询</el-button>
          <el-button @click="resetfrom('resourceRef')" size="mini">重置</el-button>
        </div>-->
      </nav>
      <aside>
        <el-button type="primary" @click="enablebtn(null)" size="mini">批量启用</el-button>
        <el-button type="warning" @click="disabledbtn(null)" size="mini">批量禁用</el-button>
        <el-button type="danger" @click="deldata(null)" size="mini">批量删除</el-button>
      </aside>
      <main>
        <!-- border边框 -->
        <el-table :data="resTabDatas" style="width: 100%" @selection-change="handleSelectionChange" height="475" stripe>
          <el-table-column type="selection" width="50" align="center" fixed="left"></el-table-column>
          <el-table-column type="index" width="50" align="center" label="序号" :index="computeTableIndex" fixed="left">
          </el-table-column>
          <el-table-column prop="roleName" label="角色名称" align="center" width="200" fixed="left"></el-table-column>
          <el-table-column prop="remark" label="角色说明" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="角色状态" width="200" align="center">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.enable" active-text="启用" inactive-text="禁用" @change="cutswitch(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="角色创建时间" align="center" width="200"></el-table-column>
          <el-table-column label="操作" width="200" align="center" fixed="right">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="编辑角色" placement="top">
                <el-button size="mini" circle @click="editdata(scope.row)">
                  <i class="btn_edit"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="角色权限" placement="top">
                <el-button circle size="mini" @click="record(scope.row)">
                  <i class="btn_view"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除角色" placement="top">
                <el-button circle size="mini" @click="deldata(scope.row.id)" style="color: #EB5757;">
                  <i class="btn_del"></i>
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination v-if="resTabDatas" background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="curpage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize"
          layout="total,sizes, prev, pager, next, jumper" :total="total"></el-pagination>
      </main>

      <!-- 添加新角色 -->
      <el-dialog title="创建角色" :visible.sync="newuserdig" width="30%" class="dialogs" :closeOnClickModal="false"
        :showClose="false" :closeOnPressEscape="false">
        <el-form :model="newuserform" :rules="newrules" ref="newruleRef">
          <el-form-item label="角色名称" label-width="80px" prop="roleName">
            <el-input v-model="newuserform.roleName" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="角色说明" label-width="80px" prop="remark">
            <el-input v-model="newuserform.remark" type="textarea" :rows="8"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveRole" size="mini">确 定</el-button>
          <el-button @click="cencleRole" size="mini">取 消</el-button>
        </div>
      </el-dialog>
      <!-- 编辑角色 -->
      <el-dialog title="编辑角色" :visible.sync="edituserdig" width="30%" class="dialogs" :closeOnClickModal="false"
        :showClose="false" :closeOnPressEscape="false">
        <el-form :model="edituserform" :rules="editrules" ref="editruleRef">
          <el-form-item label="角色名称" label-width="80px" prop="roleName">
            <el-input v-model="edituserform.roleName" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="角色说明" label-width="80px" prop="remark">
            <el-input v-model="edituserform.remark" type="textarea" :rows="8"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="updateRole" size="mini">确 定</el-button>
          <el-button @click="cencleeditRole" size="mini">取 消</el-button>
        </div>
      </el-dialog>
      <!-- 角色权限 -->
      <el-dialog title="角色权限" :visible.sync="recorddig" width="30%" class="dialogs">
        <p class="dialogp">角色名称：{{ recordname }}</p>
        <!-- <p>角色权限</p> -->
        <div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="门户权限" name="first">
              <el-tree :data="mendata" show-checkbox node-key="id" :default-expanded-keys="mendeexpand"
                :default-checked-keys="mendecheck" :props="defaultProps" ref="mentree"></el-tree>
            </el-tab-pane>
            <el-tab-pane label="后台管理权限" name="second">
              <el-tree :data="backdata" show-checkbox node-key="id" :default-expanded-keys="backdeexpand"
                :default-checked-keys="backdecheck" :props="defaultProps" ref="backtree"></el-tree>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="authOrize" size="mini">授权</el-button>
          <el-button @click="cencledig" size="mini">关闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //多选
      multipleSelection: null,
      // 搜索表单
      resourceForm: {
        roleName: null,
        enable: null,
        startTime: null,
        endTime: null,
      },

      //状态
      enableedata: [
        {
          id: 1,
          name: "启用",
        },
        {
          id: 0,
          name: "禁用",
        },
      ],
      // 角色表格
      resTabDatas: null,
      //
      // 分页
      curpage: 1, //当前页数
      pageSize: 10, //当前条数
      total: null, //总数

      // 创建角色
      newuserdig: false,
      newuserform: {
        id: null,
        roleName: null,
        remark: null,
      },
      newrules: {
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入角色说明", trigger: "blur" },
        ],
      },
      //   编辑角色
      edituserdig: false,
      edituserform: {
        roleName: null,
        remark: null,
      },
      editrules: {
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入角色说明", trigger: "blur" },
        ],
      },
      //角色权限
      recorddig: false,
      recordname: null,
      recordid: null,
      // --tabs
      activeName: "first",
      mendata: null, //门户数据
      mendeexpand: [], //默认展开
      mendecheck: [], //默认选中
      defaultProps: {
        children: "children",
        label: "content",
      },
      backdata: null, //后台数据
      backdeexpand: [], //默认展开
      backdecheck: [], //默认选中
    };
  },
  created() {
    this.queryRole();
  },
  methods: {
    //查询角色
    async queryRole() {
      const { data, status } = await this.$Http.queryRole({
        ...this.resourceForm,
        // enable: enable, //状态
        // endTime: endTime, //结束时间
        pageNo: this.curpage, //页数
        pageSize: this.pageSize, //每页数量
        // roleName: roleName, //角色名称
        // startTime: startTime, //开始时间
      });
      if (status == 200) {
        // console.log(data, status, "查询角色");
        let aData = data.list;
        for (let a = 0; a < aData.length; a++) {
          // console.log(aData[a].enable, "状态", aData[a].enableName);
          if (aData[a].enable == 0) {
            aData[a].enable = false;
          } else {
            aData[a].enable = true;
          }
        }
        this.resTabDatas = aData;
        this.total = data.total;
      }
    },
    //查询后台权限
    async queryAllBackageMen() {
      await this.$Http.queryAllBackageMen().then((res) => {
        // console.log(res, "获取后台权限");
        this.backdata = res.data;
      });
    },
    // 获取门户权限
    async queryAllPersonMen() {
      await this.$Http.queryAllPersonMen().then((res) => {
        // console.log(res, "获取门户权限");
        this.mendata = res.data;
        // this.recorddig = true;
      });
    },
    // 重置表单
    resetfrom(formName) {
      // console.log(this.$refs, "重置");
      this.$refs[formName].resetFields();
      this.queryRole();
    },
    // 开关
    cutswitch(row) {
      // console.log(row.enable, "切换开关");
      if (row.enable) {
        this.enablebtn(row.id);
        // this.disabledbtn(row.id);
      } else {
        // this.enablebtn(row.id);
        this.disabledbtn(row.id);
      }
    },
    // 启用/批量启用
    async enablebtn(id) {
      let ids = [];
      if (id == null) {
        for (let a = 0; a < this.multipleSelection.length; a++) {
          ids.push(this.multipleSelection[a].id);
        }
      } else {
        ids.push(id);
      }

      const { data, status } = await this.$Http.updateRoleStatusEnable({
        roleId: ids, //角色id数组
      });
      if (status == 200) {
        // console.log(data, status, "启用角色");
        this.queryRole();
      }
    },
    // 禁用/批量禁用
    async disabledbtn(id) {
      let ids = [];
      if (id == null) {
        for (let a = 0; a < this.multipleSelection.length; a++) {
          ids.push(this.multipleSelection[a].id);
        }
      } else {
        ids.push(id);
      }
      const { data, status } = await this.$Http.updateRoleStatusDisable({
        roleId: ids, //角色id数组
      });
      if (status == 200) {
        // console.log(data, status, "禁用角色");
        this.queryRole();
      }
    },
    // 删除/批量删除
    async deldata(id) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let ids = [];
          // console.log(this.multipleSelection, "multipleSelection", id);
          if (id == null) {
            for (let a = 0; a < this.multipleSelection.length; a++) {
              ids.push(this.multipleSelection[a].id);
            }
          } else {
            ids.push(id);
          }
          // console.log(ids, ids);
          const { data, status } = await this.$Http.deleteRole({
            roleId: ids, //角色id数组
          });
          if (status == 200) {
            // console.log(data, status, "删除角色");
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.curpage = 1;
            this.queryRole();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 创建新角色
    createuser() {
      console.log("创建新角色");
      this.newuserdig = true;
    },
    // --确定
    async saveRole() {
      this.$refs["newruleRef"].validate(async (valid) => {
        if (valid) {
          const { data, status } = await this.$Http.saveRole({
            ...this.newuserform,
          });
          if (status == 200) {
            // console.log(data, status, "添加角色");
            this.$message.success("添加成功");
            this.queryRole();
            this.cencleRole();
          }
        } else {
          return false;
        }
      });
    },
    // --取消
    cencleRole() {
      this.$refs["newruleRef"].resetFields();
      this.newuserdig = false;
      // this.newuserform.roleName = null;
      // this.newuserform.remark = null;
    },
    //
    //编辑角色
    editdata(row) {
      // console.log(row, "编辑角色");
      this.edituserform.roleName = row.roleName;
      this.edituserform.remark = row.remark;
      this.edituserform.id = row.id;
      this.edituserdig = true;
    },
    // --确定
    async updateRole() {
      this.$refs["editruleRef"].validate(async (valid) => {
        // console.log(this.edituserform, "edituserform", valid);
        if (valid) {
          const { data, status } = await this.$Http.updateRole({
            // id: id, //角色id
            ...this.edituserform,
            // remark: remark, //角色说明
            // roleName: roleName, //角色名称
          });
          if (status == 200) {
            // console.log(data, status, "修改角色");
            this.cencleeditRole();
            this.queryRole();
          }
        } else {
          return false;
        }
      });
    },
    // --取消
    cencleeditRole() {
      this.$refs["editruleRef"].resetFields();
      this.edituserdig = false;
    },

    // 角色权限
    async record(row) {
      // console.log(row, "角色权限");
      this.queryAllBackageMen();
      this.queryAllPersonMen();
      this.recordname = row.roleName;
      this.recordid = row.id;
      // 门户
      this.mendeexpand = [];
      this.mendecheck = [];
      // 后台
      this.backdeexpand = [];
      this.backdecheck = [];
      this.queryRoleById(row.id);
    },
    // 查询角色权限
    async queryRoleById(id) {
      console.log(this.mendeexpand, this.mendecheck, "ljls111111");
      let from = new FormData();
      from.append("roleId", id);
      await this.$Http.queryRoleById(from).then((res) => {
        if (res.status == 200) {
          console.log(res, "查看角色权限", this.mendata);
          let cc = [];
          for (let a = 0; a < this.mendata.length; a++) {
            for (let b = 0; b < this.mendata[a].children.length; b++) {
              for (let c = 0; c < res.data.gateWayMenuList.length; c++) {
                if (
                  this.mendata[a].children[b].id == res.data.gateWayMenuList[c]
                ) {
                  cc.push(a + 1);
                }
              }
            }
          }
          let rr = [...new Set(cc)];
          this.mendeexpand = rr;
          this.mendecheck = res.data.gateWayMenuList;
          let ccc = [];
          for (let a = 0; a < this.backdata.length; a++) {
            for (let b = 0; b < this.backdata[a].children.length; b++) {
              for (let c = 0; c < res.data.backageMenuList.length; c++) {
                if (
                  this.backdata[a].children[b].id == res.data.backageMenuList[c]
                ) {
                  ccc.push(a + 1);
                }
              }
            }
          }
          let rrr = [...new Set(ccc)];

          this.backdeexpand = rrr;
          this.backdecheck = res.data.backageMenuList;
          this.recorddig = true;
        }
      });
    },
    // --授权
    async authOrize() {
      // console.log(this.mendata, "shou门户权限");
      let menids = this.$refs.mentree.getCheckedKeys();
      let menhalefids = this.$refs.mentree.getHalfCheckedKeys();
      let backids = this.$refs.backtree.getCheckedKeys();
      let backhalfids = this.$refs.backtree.getHalfCheckedKeys();
      console.log(menids, menhalefids, "menhalefids", backids, backhalfids);
      let aa = [...menids, ...menhalefids];
      let bb = [...backids, ...backhalfids];
      // return
      const { data, status, message } = await this.$Http.authOrize({
        gateWayMenu: aa, //门户的菜单
        backageMenu: bb, //后台管理的菜单
        roleId: this.recordid, //角色id
      });
      if (status == 200) {
        // console.log(data, status, message, "授权");
        this.$message({
          message: message,
          type: "success",
        });
      }
    },
    // --关闭
    cencledig() {
      let menids = this.$refs.mentree.getCheckedKeys();
      for (let a = 0; a < menids.length; a++) {
        this.$refs.mentree.setChecked(menids[a], false);
      }
      let backids = this.$refs.backtree.getCheckedKeys();
      for (let b = 0; b < backids.length; b++) {
        this.$refs.backtree.setChecked(backids[b], false);
      }
      this.recorddig = false;
      this.activeName = "first";
    },

    //
    // 多选
    handleSelectionChange(val) {
      // console.log(val, "xuzhogn");
      this.multipleSelection = val;
    },
    //监听index
    computeTableIndex(index) {
      // console.log(index, this.queryInfo.pagenum, this.queryInfo.pagesize, 'e')
      return (this.curpage - 1) * this.pageSize + index + 1;
    },

    //切换tabs
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.queryRole();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.curpage = val;
      this.queryRole();
    },
  },
};
</script>
<style lang="scss" scoped>
.resource {
  .resource_center {
    header {
      display: flex;
      justify-content: space-between;
      // width: 1688px;
      height: 70px;
      background: #ffffff;
      padding: 0 20px;

      p {
        // width: 108px;
        height: 70px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 600;
        color: #141516;
        line-height: 70px;
      }

      .el-button {
        height: 30px;
        margin: 20px 0 0 0;
        // .btns_icon {
        //   width: 13px;
        //   height: 15px;
        //   display: inline-block;
        //   background: url("../../../../assets/userinfo/upload.png") no-repeat;
        //   vertical-align: text-top;
        // }
      }
    }

    nav {
      background-color: #fff;
      margin: 10px 0;
      padding: 20px;

      // .el-form-item{
      //   width: 230px;
      // }
      .el-input {
        width: 150px;
      }

      .el-select {
        width: 120px;
      }

      //   .nav_btns {
      //     display: flex;
      //     justify-content: flex-end;
      //   }
    }

    aside {
      margin-top: 10px;
      background-color: #fff;
      padding: 20px;
    }

    main {
      padding: 0 20px;
      background-color: #fff;

      i {
        display: inline-block;
        width: 19px;
        height: 18px;
      }

      .btn_edit {
        background: url("../../../../assets/backsys/edit.png") no-repeat;
      }

      .btn_view {
        background: url("../../../../assets/backsys/view.png") no-repeat;
      }

      .btn_del {
        background: url("../../../../assets/backsys/del.png") no-repeat;
      }

      .el-pagination {
        display: flex;
        justify-content: center;
        padding: 20px 0;
      }
    }

    .checkTabs {
      .butognguo {
        margin-top: 23px;
        display: flex;
        justify-content: flex-end;
      }
    }

    .dialogs {
      ::v-deep .el-dialog__title {
        // width: 72px;
        // height: 24px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 600;
        color: #141516;
        // line-height: 21px;
      }

      ::v-deep .el-dialog__body {
        padding: 10px 20px 0;
      }

      .dialogp {
        // width: 140px;
        // height: 18px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #5d6066;
        // line-height: 16px;
      }

      .el-input {
        width: 400px;
      }

      .el-textarea {
        width: 400px;
      }

      .dialog-footer {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>