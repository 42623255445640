import { render, staticRenderFns } from "./postcir.vue?vue&type=template&id=e8ebb2f2&scoped=true&"
import script from "./postcir.vue?vue&type=script&lang=js&"
export * from "./postcir.vue?vue&type=script&lang=js&"
import style0 from "./postcir.vue?vue&type=style&index=0&id=e8ebb2f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8ebb2f2",
  null
  
)

export default component.exports