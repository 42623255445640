<template>
  <div class="classrecom">
    <div class="classrecomcenter">
      <!-- <h1>课程推荐管理</h1> -->
      <header>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="全部课程" name="first">
            <aside>
              <p @click="cutall(true)" :style="cc">普通课程</p>
              <p @click="cutall(false)" :style="ccc">系列课程</p>
            </aside>
            <nav>
              <pt v-if="showall"></pt>
              <ser v-else></ser>
            </nav>
          </el-tab-pane>
          <el-tab-pane label="推荐课程" name="second">
            <aside>
              <p @click="cutalls(true)" :style="acc">普通课程</p>
              <p @click="cutalls(false)" :style="accc">系列课程</p>
            </aside>
            <nav>
              <apt v-if="showalls"></apt>
              <aser v-else></aser>
            </nav>
          </el-tab-pane>
        </el-tabs>
      </header>
    </div>
  </div>
</template>
<script>
import pt from "./components/ptclass.vue";
import ser from "./components/serclass.vue";
import apt from "./components/aptclass.vue";
import aser from "./components/aserclass.vue";
export default {
  data() {
    return {
      activeName: "first",
      //
      showall: true,
      showalls: true,
    };
  },
  methods: {
    //
    cutall(stat) {
      this.showall = stat;
    },
    cutalls(stat) {
      this.showalls = stat;
    },
    // 切换tabs
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
  computed: {
    cc() {
      if (this.showall) {
        return `color: #1977f9;`;
      } else {
        return `color: #141516;`;
      }
    },
    ccc() {
      if (this.showall) {
        return `color: #141516;`;
      } else {
        return `color: #1977f9;`;
      }
    },
    acc() {
      if (this.showalls) {
        return `color: #1977f9;`;
      } else {
        return `color: #141516;`;
      }
    },
    accc() {
      if (this.showalls) {
        return `color: #141516;`;
      } else {
        return `color: #1977f9;`;
      }
    },
  },
  components: {
    pt,
    ser,
    apt,
    aser,
  },
};
</script>
<style lang="scss" scoped>
.classrecom {
  .classrecomcenter {
    header {
      background: #ffffff;
      //   border-radius: 4px 4px 4px 4px;
      .el-tabs {
        ::v-deep .el-tabs__item {
          //   background-color: pink;
          //   width: 72px;
          //   height: 24px;
          //   font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 600;
          color: #141516;
          //   line-height: 21px;
        }
        ::v-deep .el-tabs__header {
          padding: 10px 20px 0;
        }
        aside {
          display: flex;
          justify-content: flex-start;
          padding: 0px 20px 0;
          p {
            margin-right: 20px;
            // width: 56px;
            // height: 18px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 600;
            // line-height: 16px;
            cursor: pointer;
          }
          p:hover {
            color: #1977f9;
          }
        }
        nav {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>